export const FrolicEvents = {
    hideWidget: () => {
      const event = { type: "FrolicHideWidget" };
      window.postMessage(event, "*");
    },
    setCrossPositionInLandscape: () => {
      const event = { type: "FrolicLandscapeCross" };
      window.postMessage(event, "*");
    },
    setCrossPositionInPortrait: () => {
      const event = { type: "FrolicPortraitCross" };
      window.postMessage(event, "*");
    },
  };
