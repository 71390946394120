// Created by Shree
import axios from "axios";
class FrolicGameEvent {
  resultScreen = null;
  constructor(gameData, gameWindow, sessionId, resultScreen) {
    this.gameData = gameData;
    this.gameWindow = gameWindow;
    this.sessionId = sessionId;
    this.resultScreen = resultScreen;
  }

  subscribe() {
    const that = this;
    if (typeof window !== "undefined") {
      window.addEventListener("message", that.onGameEvent);
    }
  }

  unsubscribe() {
    const that = this;
    if (typeof window !== "undefined") {
      window.removeEventListener("message", that.onGameEvent);
    }
  }

  onGameLoad() {
    console.log(this.gameData);
    // const gameData = {
    //   apiData: {
    //     match_making_data: {
    //       game_mode_type: "tournament",
    //       attempt_id: "64dc6d12009511a8a6b4a292",
    //       game_server_data: {
    //         server_info: "NA",
    //         server_type: "frolic",
    //       },
    //       session_id: "64dc6d12009511a8a6b4a292",
    //       contest_id: "_vy710Z8jCJDkjJc",
    //       game_id: "",
    //     },
    //   },
    //   playerData: {
    //     avatar:
    //       "https://hdn-1.fynd.com/company/884/applications/000000000000000000000001/theme/pictures/free/original/default-profile_nxhzui.png",
    //     id: "64b0e72ff39fa44c7a753d90",
    //     username: "Shree Mahadik",
    //   },
    // };
    this.gameWindow.postMessage(JSON.stringify(this.gameData), "*");
  }
  onGameStarted(data) {
    return data;
  }

  onScoreUpdated(data) {
    return data;
  }

  async onGameFinished(data) {
    let endScore = data.split("}");
    let string = endScore[0] + "}";
    string = JSON.parse(string);
    const gameOverPayLoad = {
      gameSessionId: this.sessionId,
      score: string.message,
      reason: "GAME_OVER",
    };
    await axios.post(
      `${location.origin}/ext/fynd-engage/application/engage/v1.0/game/frolicGames/gameEnd`,
      gameOverPayLoad
    );
    const xorData = data.split(".");
    const unEncryptedScore = xorData[2] ^ xorData[3];
    const dataPayload = {
      score: unEncryptedScore,
      isOutOfAttempts: false,
      sessionId: this.sessionId,
    };
    this.resultScreen(dataPayload);
    this.unsubscribe();
    return dataPayload;
  }
  onGameEvent = (event) => {
    try {
      const jsonData = JSON.parse(event.data);
      switch (jsonData.eventName) {
        case "onGameLoad": {
          this.onGameLoad();
          break;
        }
        case "onGameStarted": {
          this.onGameStarted(jsonData.value);
          break;
        }
        case "onScoreUpdated": {
          this.onScoreUpdated(jsonData.value);
          break;
        }
        case "onGameFinished": {
          this.onGameFinished(jsonData.value);
          break;
        }
        default: {
          console.warn("Unknown event");
        }
      }
    } catch (e) {
      console.error(e);
    }
    // const value = jsonData.value;
    // return value;
    // switch (event.type) {
    //     case
    // }
  };
}

export default FrolicGameEvent;
