
class BaseWidget {
    constructor() {
      if (this.show === BaseWidget.prototype.show) {
        throw new Error('Child class must override the show() method');
      }
  
      if (this.hide === BaseWidget.prototype.hide) {
        throw new Error('Child class must override the hide() method');
      }
  
      if (this.resize === BaseWidget.prototype.getWidgetComponent) {
        throw new Error('Child class must override the resize() method');
      }
  
      if (this.update === BaseWidget.prototype.render) {
        throw new Error('Child class must override the update() method');
      }
    }
  
    show() {
      throw new Error('Child class must override the show() method');
    }
  
    hide() {
      throw new Error('Child class must override the hide() method');
    }
  
    getWidgetComponent() {
      throw new Error('Child class must override the resize() method');
    }
  
    render() {
      throw new Error('Child class must override the update() method');
    }
  }
  export default BaseWidget;
  
  