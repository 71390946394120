var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app"}},[_c('div',{class:_vm.isLandscape
        ? 'frolicgames-container-landscape'
        : 'frolicgames-container',style:({ backgroundImage: `url(${_vm.backgroundUrl})` }),on:{"is-Landscape":_vm.updateIsLandscape}},[_c('div',{staticClass:"body"},[(_vm.loader)?_c('div',{attrs:{"id":"loader"}}):_vm._e(),(_vm.resultScreen)?_c('div',{staticClass:"home-button-container"},[(_vm.resultScreen)?_c('HomeSvg',{staticClass:"home-btn",on:{"home-button-click":_vm.homeButtonClick}}):_vm._e()],1):_vm._e(),(_vm.showMainScreen && _vm.gameChallenges)?_c('MainScreenSvg',{staticClass:"main-screen",attrs:{"color1":_vm.colorSettings.secondary,"color2":_vm.colorSettings.primary,"color3":_vm.colorSettings.fontColor,"targetScore":_vm.gameChallenges[_vm.counter]?.targetScore || 10,"buttonText":_vm.getButtonText(_vm.gameChallenges[_vm.counter])},on:{"update-screens-event":_vm.updateScreensHandler}}):_vm._e(),(_vm.showStartGameScreen)?_c('div',{staticClass:"gameplay-container"},[_c('img',{staticClass:"game-banner",attrs:{"src":_vm.gameChallenges[_vm.counter]?.gameDetails.games.battle_room_banner}}),(_vm.showMainScreen && _vm.gameChallenges)?_c('ArrowSvg',{staticClass:"arrow",attrs:{"counter":_vm.counter,"total-length":_vm.arrayLength},on:{"increase-decrease-games-counter":_vm.buttonCounter}}):_vm._e(),_c('div',{staticClass:"game-name"},[_vm._v(" "+_vm._s(_vm.gameChallenges[_vm.counter]?.gameDetails?.games?.name)+" ")]),_c('div',{staticClass:"coupon-title-container"},[(_vm.showStartGameScreen)?_c('div',{staticClass:"coupon-title",style:({
              color: `${_vm.colorSettings.primary}`,
            })},[_c('p',[_vm._v(" Stand a chance to win"),_c('br'),_vm._v(" "+_vm._s(_vm.gameChallenges[_vm.counter]?.winningAmount)+" Points ")])]):_vm._e()])],1):_vm._e(),(_vm.resultScreen)?_c('div',{staticClass:"result-screen"},[_c('img',{staticClass:"game-over-image",attrs:{"src":_vm.gameChallenges[_vm.counter]?.gameDetails.games.battle_room_banner}}),_c('div',{staticClass:"text-container"},[_c('div',{staticClass:"score"},[_vm._v(" Your Score: "),_c('span',[_vm._v(_vm._s(_vm.playerScore))])]),(_vm.isWinScreen || _vm.retryScreen || _vm.noRetryScreen || _vm.alreadyWon)?_c('div',{staticClass:"game-result-text"},[_vm._v(" "+_vm._s(_vm.gameState)+" ")]):_vm._e(),(_vm.isWinScreen)?_c('div',{staticClass:"coupon-text"},[_vm._v(" "+_vm._s(_vm.gameChallenges[_vm.counter]?.message?.winMessage)+" "),_c('span',[_vm._v(_vm._s(_vm.gameChallenges[_vm.counter]?.winningAmount)+" Points")])]):_vm._e(),(_vm.alreadyWon)?_c('div',{staticClass:"coupon-text"},[_vm._v(" You've already won "),_c('span',[_vm._v(_vm._s(_vm.gameChallenges[_vm.counter]?.winningAmount)+" Points")])]):_vm._e(),(_vm.retryScreen)?_c('div',{staticClass:"coupon-text"},[_vm._v(" Retry you have "+_vm._s(_vm.attemptsLeft)+" Attempts left ")]):_vm._e(),(_vm.noRetryScreen)?_c('div',{staticClass:"coupon-text"},[_vm._v(" 0 Attempt Left. You can play again tomorrow ")]):_vm._e(),(_vm.retryScreen || _vm.noRetryScreen)?_c('div',{staticClass:"empty-div"}):_vm._e()])]):_vm._e(),(_vm.showGameScreen)?_c('GameScreen',{staticClass:"game-screen",attrs:{"gameHostUrl":_vm.gameChallenges[_vm.counter]?.gameDetails?.games.host_url,"challenge":_vm.gameChallenges[_vm.counter]?.id,"targetProp":_vm.gameChallenges[_vm.counter]?.targetScore || 10,"isLandscape":_vm.landscape(
            _vm.gameChallenges[_vm.counter]?.gameDetails?.games?.options?.orientation
          )},on:{"is-Landscape":_vm.updateIsLandscape,"on-result-screen-load":_vm.resultScreenLoad}}):_vm._e(),_c('div',{attrs:{"id":"error"}}),_c('div',{staticClass:"game-component"})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }