import BaseWidget from "../BaseWidget.js";
import App from "./App.vue";
import style from "./style.js";

import Vue from "vue";

const {
  base_url: iframeSrc,
  application_id: applicationId,
  company_id: companyId,
  user,
} = window.__frolic_engage?.conf;

console.log(iframeSrc);
console.log(applicationId);
console.log(companyId);
console.log(user);

const ORIENTATIONS = {
  LANDSCAPE: "Landscape",
  PORTRAIT: "Portrait",
};

// Create a new Vue instance
let app;

class FrolicGames extends BaseWidget {
  constructor(widgetConfig) {
    super();
    this.widgetConfig = widgetConfig;
    this.isRender = false;
    this.state = "closed";
    this.openCount = 0;
    this.softHide = false;
    this.orientation = ORIENTATIONS.PORTRAIT;
  }

  getIframe() {
    if (!this.iframe) {
      this.iframe = document.createElement("iframe");
    }
    return this.iframe;
  }

  getDiv() {
    if (!this.div) {
      this.div = document.createElement("div");
    }
    return this.div;
  }

  getinnerDiv() {
    if (!this.innerDiv) {
      this.innerDiv = document.createElement("div");
    }
    return this.innerDiv;
  }

  getBaseElement() {
    if (!this.baseelement) {
      this.baseelement = document.getElementsByClassName("baseview");
    }
    return this.baseelement;
  }

  show() {
    let div = this.getDiv();
    let innerDiv = this.getinnerDiv();
    let baseelement = this.getBaseElement();
    div.className = "slot-machine-box-open_game_settings";
    innerDiv.className = "inner-div";
    innerDiv.id = "frolic-game-widget_";
    document.getElementById("logo-show-cross_game_settings");
    let cross = document.getElementById("logo-div-cross-closed_game_settings");
    if (cross) {
      cross.style.zIndex = 99;
    }
    if (!app) {
      app = new Vue({
        render: (h) => h(App),
      });
      app.$mount("#frolic-game-widget_");
    }

    if (this.orientation === ORIENTATIONS.PORTRAIT) {
      const closedGameParentDiv = document.getElementsByClassName("frolic-app");
      closedGameParentDiv[0].style.display = "flex";
    } else {
      const closedGameParentDiv = document.getElementsByClassName(
        "frolic-app-landscape"
      );
      closedGameParentDiv[0].style.display = "flex";
    }

    this.state = "open";
    baseelement[0].style.opacity = 0.3;
    if (this.widgetConfig.distributionChannel === "popup-left") {
      const logo = document.getElementById("logo-show_game_settings");
      logo.style.display = "none";
    }
    const crossElement = document.getElementById(
      "logo-show-cross_game_settings"
    );
    crossElement.style.display = "initial";
  }

  hide() {
    let div = this.getDiv();
    let innerDiv = this.getinnerDiv();
    let baseelement = this.getBaseElement();

    div.className = "slot-machine-box-closed_game_settings";
    innerDiv.className = "contents-div-closed_game_settings";
    this.state = "closed";
    baseelement[0].style.opacity = 1;
    if (this.orientation === ORIENTATIONS.PORTRAIT) {
      const closedGameParentDiv = document.getElementsByClassName("frolic-app");
      closedGameParentDiv[0].style.display = "none";
    } else {
      const closedGameParentDivLandscape = document.getElementsByClassName(
        "frolic-app-landscape"
      );
      closedGameParentDivLandscape[0].style.display = "none";
    }
    if (this.widgetConfig.distributionChannel === "popup-left") {
      const logo = document.getElementById("logo-show_game_settings");
      logo.style.display = "initial";
    }
    const crossElement = document.getElementById(
      "logo-show-cross_game_settings"
    );
    crossElement.style.display = "none";
    this.openCount = this.openCount + 1;
    if (window.screen.availWidth <= 400) {
      const iframe = document.querySelector(".actual-iframe");
      if (iframe) {
        iframe.remove();
      }
    }
    if (this.fetchData) {
      const event = {
        type: "fetchData",
      };
      window.postMessage(event, "*");
    }
  }
  setCrossPositionInLandscape() {
    this.orientation = ORIENTATIONS.LANDSCAPE;
    const crossElement = document.getElementById(
      "logo-show-cross_game_settings"
    );
    crossElement.classList.remove("portrait-cross");
    crossElement.classList.add("landscape-cross");
  }
  setCrossPositionInPortrait() {
    this.orientation = ORIENTATIONS.PORTRAIT;
    const crossElement = document.getElementById(
      "logo-show-cross_game_settings"
    );
    crossElement.classList.remove("landscape-cross");
    crossElement.classList.add("portrait-cross");
  }

  logohide() {
    const containerDiv = document.querySelector(".container-div_game_settings");
    const mainDiv = document.querySelector(".main-div_game_settings");
    const slotMachineBox = document.querySelector(
      ".slot-machine-box-closed_game_settings"
    );
    if (slotMachineBox) {
      slotMachineBox.remove();
    }
    if (containerDiv) {
      containerDiv.remove();
    }
    if (mainDiv) {
      mainDiv.remove();
    }
    this.isRender = false;
  }

  logoClickHandler(isBanner = false) {
    if (isBanner) {
      this.softHide = true;
    }
    if (this.state == "closed") {
      if (this.openCount > 0) {
        let innerDiv = this.getinnerDiv();
        console.log(innerDiv);
      }
      this.show();
    } else {
      this.hide();
    }
  }

  init(callback) {
    if (this.isRender === false) {
      this.render(document.body, document.head, callback);
    } else {
      callback(this);
    }
  }
  handlePostmessage = (event) => {
    if (event && event?.data?.type === "FrolicHideWidget") {
      this.hide();
    }
    if (event && event?.data?.type === "FrolicLandscapeCross") {
      this.setCrossPositionInLandscape();
    }
    if (event && event?.data?.type === "FrolicPortraitCross") {
      this.setCrossPositionInPortrait();
    }
  };
  handleGameOpen = (event) => {
    if (event && event?.data?.type === "FrolicOpenWidget") {
      this.show();
      this.fetchData = true;
    }
  };
  gameGetWidgetComponent() {
    const that = this;
    that.softHide = false;
    const stylesheet = document.createElement("style");
    // const baseelement = this.getBaseElement();
    stylesheet.textContent = style;
    const maindiv = document.createElement("div");
    maindiv.className = "main-div_game_settings";
    const div = this.getDiv();
    div.className = "container-div_game_settings";

    const logoDiv = document.createElement("div");
    logoDiv.className = "logo-div-closed_game_settings";
    if (this.widgetConfig.distributionChannel === "popup-left") {
      if (
        this.widgetConfig.distributionChannel.pop_up_position === "bottom-right"
      ) {
        logoDiv.style.left = "initial";
        logoDiv.style.right = "15px";
      }
    }
    const img = document.createElement("img");
    img.src = this.widgetConfig.distributionChannel.popup_logo
      ? this.widgetConfig.distributionChannel.popup_logo
      : "https://cdn.pixelbin.io/v2/frolic/frlcde/original/images/game-battle-room-banner/io087hBXfB-spinner-partner.gif";
    img.id = "logo-show_game_settings";

    img.addEventListener("click", () => this.logoClickHandler());
    logoDiv.append(img);
    const logoDivCross = document.createElement("div");
    logoDivCross.className = "logo-div-cross-closed_game_settings";
    const imgCross = document.createElement("img");
    imgCross.src =
      "https://cdn.pixelbin.io/v2/frolic/original/__playground/close.png";
    imgCross.id = "logo-show-cross_game_settings";
    imgCross.classList.add("portrait-cross");
    imgCross.addEventListener("click", () => this.logoClickHandler());
    logoDivCross.append(imgCross);
    let innerDiv = this.getinnerDiv();
    // Widget container...
    const appContainer = document.createElement("div");
    appContainer.id = "frolic-game-widget";
    appContainer.className = "frolic-game-widget";

    return {
      stylesheet,
      widget: appContainer,
      popupIcon: logoDiv,
      innerDiv,
      logoDivCross,
      div,
    };
  }
  render(bodyElement, headElement, callback) {
    const { stylesheet, widget, popupIcon, innerDiv, logoDivCross, div } =
      this.gameGetWidgetComponent();
    headElement.append(stylesheet);
    bodyElement.append(widget);
    if (this.widgetConfig.distributionChannel === "popup-left") {
      if (!div.querySelector(".contents-div-closed_game_settings")) {
        div.append(innerDiv);
      }
      if (!div.querySelector(".logo-div-closed_game_settings")) {
        div.append(popupIcon);
      }
      if (!div.querySelector(".logo-div-cross-closed_game_settings")) {
        div.append(logoDivCross);
      }
      bodyElement.append(div);
    } else {
      if (!div.querySelector(".contents-div-closed_game_settings")) {
        div.append(innerDiv);
      }
      if (!div.querySelector(".logo-div-cross-closed_game_settings")) {
        div.append(logoDivCross);
      }
      bodyElement.append(div);
    }
    this.isRender = true;
    let gameInnerDiv = this.getinnerDiv();
    gameInnerDiv.className = "inner-div";
    gameInnerDiv.id = "frolic-game-widget_";
    document.getElementById("logo-show-cross_game_settings");
    if (!app) {
      app = new Vue({
        render: (h) => h(App),
      });
      app.$mount("#frolic-game-widget_");
    }
    const closedGameParentDiv = document.getElementsByClassName("frolic-app");
    closedGameParentDiv[0].style.display = "none";
    callback(this);
  }
}
const frolicGames = new FrolicGames(
  window.__frolic_engage.getWidgetConfig("frolic-games")
);
window.__frolic_engage.registerWidget("frolic-games", frolicGames);

window.addEventListener("message", frolicGames.handlePostmessage);

window.addEventListener("message", frolicGames.handleGameOpen);
