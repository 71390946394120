<template>
    <Frolic
     @is-Landscape="updateIsLandscape"
     :class="isLandscape ? 'frolic-app-landscape' : 'frolic-app'" 
    />
</template>

<script>
import Frolic from "./Widget.vue";
export default {
    name: "frolic-app",
    data() {
        return {
            isLandscape: false,
        };
    },
    components: {
        Frolic,
    },
    watch: {},
    methods: {
        handleURLChange() {
            this.currentURL = window.location.href;
        },
        updateIsLandscape(value) {
            this.isLandscape = value;
        },
    },
};
</script>

<style scoped>
.frolic-app {
    width: 100%;
    max-width: 25rem;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
}
.frolic-app-landscape {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-content: flex-start;
    justify-content: center;
    align-items: center;
}

/* Mobile screens (320px to 480px) */
@media screen and (min-width: 320px) and (max-width: 480px) {
    .frolic-app {
        width: 100%;
        height: 100%;
    }
    .frolic-app-landscape {
        width: 200vh;
    }
}

/* Laptop and Tablet screens (481px to 1024px) */
@media screen and (min-width: 481px) and (max-width: 1024px) {
    .frolic-app {
        width: 100%;
        height: 100%;
    }
}

/* Large desktops (1024px to 1919px) */
@media screen and (min-width: 1024px) and (max-width: 1919px) {
    .frolic-app {
        width: 100%;
        height: 100%;
    }
}
</style>
