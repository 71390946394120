const style = `
  .logo-div-closed_game_settings {
      position: fixed;
      overflow: hidden;
      bottom: 15px;
      left: 15px;
      height: 50px;
      width: 50px;
      z-index: 100;
      cursor: pointer;
  }
  
  .logo-div-open_game_settings {
      height: 0px;
      width: 0px;
      cursor: pointer;
  }
  
  .slot-machine-box-open_game_settings {
    height: 100%;
    width: 100%;
    z-index: 9999;
    position: fixed;
    left: 0;
    display: flex;
    justify-content: center;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.8);
  }
  
  .slot-machine-box-closed_game_settings {
      display: block;
  }
  
  .contents-div-open_game_settings {
      position: fixed;
      width: 25%;
      padding: 1px;
      top: 50px;
      height: 700px;
      left: 37%;
      border-radius: 20px;
      overflow: hidden;
  }
  
  .contents-div-closed_game_settings {
      display: none;
  }
  
  .close-div-open_game_settings {
      display: flex;
      justify-content: end;
      height: 50px;
  }
  
  #close-btn-show_game_settings {
      cursor: pointer;
      height: 50px;
      width: 50px;
  }
  
  #close-btn-hide_game_settings {
      display: none;
  }
  
  #logo-show_game_settings {
      max-height: 100%;
      max-width: 100%;
      border-radius: 50%;
  }
  #logo-show-cross_game_settings {
    display: none;
    cursor: pointer;
  }
  .portrait-cross {
    position: fixed;
    width: 2.25rem;
    height: 2.25rem;
    top: 5rem;
    right: 33rem;
    transform: translate(25%, -25%);
  }
  .landscape-cross {
    position: fixed;
    width: 2.25rem;
    height: 2.25rem;
    top: 6rem;
    right: 20rem;
    z-index: 1;
  }
  
  #logo-hide_game_settings {
      display: none;
  }
  
  #frame-container_game_settings {
      height: 100%;
      width: 100%;
  }
  @media only screen and (min-width: 1920px) {
  
    .contents-div-open_game_settings{
        width: 20%;
    }
    .portrait-cross {
        right: 46rem;
        top: 5rem;
    }
    .landscape-cross {
        top: 7rem;
        right: 26rem;
    }
  }
  @media only screen and (max-width: 1440px) {
  
      .contents-div-open_game_settings{
          width: 25%;
      }
      
  }
  @media only screen and (max-width: 1200px) {
  
      .contents-div-open_game_settings{
          width: 38%;
          left:31%;
      }
  }
  @media only screen and (max-width: 801px) {
  
      .contents-div-open_game_settings{
          width: 49%;
          left:26%;
      }
  }
  @media only screen and (max-width: 801px) {
  
      .contents-div-open_game_settings{
          width: 49%;
          left:26%;
      }
  }
  @media (min-width: 768px) and (max-width: 800px){
      .contents-div-open_game_settings{
          top: 0px;
      }
  }
  @media only screen and (max-width: 600px) {
      .contents-div-open_game_settings{
          width: 100%;
          left: 0%;
          height: 100%;
          top: 0px;
      }
    .portrait-cross {
        top: 1.35rem;
        right: 0.96rem;
    }
    .landscape-cross {
        top: 38rem;
        right: 1rem;
    }
      .logo-div-closed_game_settings{
        display: initial;
      }
    .slot-machine-box-open_game_settings {
        height: 100%;
        width: 100%;
        z-index: 9999;
        position: fixed;
        left: 0;
        display: flex;
        justify-content: center;
        width: 100%;
        height: 110%;
        background-color: rgb(0,0,0);
        background-color: rgba(0,0,0,0.8);
    }
  }
  `;

export default style;
