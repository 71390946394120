// Base URL for Frolic Extension APIs
const BASE_URL = "/ext/fynd-engage";

// URL for active game challenges
export const gameUrl = `${BASE_URL}/application/engage/v1.0/game/frolicGames/gamesCampaign`;

// URL proxy function
export const proxyUrl = (url) => {
  return url.replace(
    "https://games.frolicz0.de/",
    `${BASE_URL}/application/game-proxy/`
  );
};

// URL for theme settings
export const THEME_SETTINGS_URL = `${BASE_URL}/application/engage/v1.0/game/frolicGames/gamesCampaign`;

// URL for claiming a game
export const CLAIM_GAME_URL = (campaignId) => {
  return `${BASE_URL}/application/engage/v1.0/game/frolicGames/claimReward?campaignId=${campaignId}`;
};
