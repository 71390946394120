<template>
    <svg
        width="96"
        height="96"
        viewBox="0 0 96 96"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="home-button"
        @click="homeButtonClickHandler"
    >
        <rect
            x="1"
            y="1"
            width="94"
            height="94"
            rx="15"
            fill="#050505"
            class="home-button"
            @click="homeButtonClickHandler"
        />
        <path
            d="M41.3332 71.3337V54.6671H54.6665V71.3337C54.6665 73.1671 56.1665 74.6671 57.9998 74.6671H67.9998C69.8332 74.6671 71.3332 73.1671 71.3332 71.3337V48.0004H76.9998C78.5332 48.0004 79.2665 46.1004 78.0998 45.1004L50.2332 20.0004C48.9665 18.8671 47.0332 18.8671 45.7665 20.0004L17.8998 45.1004C16.7665 46.1004 17.4665 48.0004 18.9998 48.0004H24.6665V71.3337C24.6665 73.1671 26.1665 74.6671 27.9998 74.6671H37.9998C39.8332 74.6671 41.3332 73.1671 41.3332 71.3337Z"
            fill="white"
            class="home-button"
            @click="homeButtonClickHandler"
        />
        <rect
            x="1"
            y="1"
            width="94"
            height="94"
            rx="15"
            stroke="#2F2F2F"
            stroke-width="2"
            class="home-button"
            @click="homeButtonClickHandler"
        />
    </svg>
</template>
<script>
export default {
    methods: {
        homeButtonClickHandler() {
            this.$emit("home-button-click");
        },
    },
};
</script>
<style>
.home-button{
    cursor: pointer;
}
</style>