import axios from "axios";
import { CLAIM_GAME_URL, THEME_SETTINGS_URL } from "./url";

export async function fetchThemeSettings() {
  const response = await axios.get(`${THEME_SETTINGS_URL}`);
  return response.data;
}

export async function claimGame(campaignId) {
  const response = await axios.get(CLAIM_GAME_URL(campaignId));
  return response.data;
}
