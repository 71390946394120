<template>
  <div id="app">
    <div
      @is-Landscape="updateIsLandscape"
      :class="
        isLandscape
          ? 'frolicgames-container-landscape'
          : 'frolicgames-container'
      "
      :style="{ backgroundImage: `url(${backgroundUrl})` }"
    >
      <div class="body">
        <div id="loader" v-if="loader"></div>
        <div class="home-button-container" v-if="resultScreen">
          <HomeSvg
            class="home-btn"
            @home-button-click="homeButtonClick"
            v-if="resultScreen"
          />
        </div>

        <MainScreenSvg
          :color1="colorSettings.secondary"
          :color2="colorSettings.primary"
          :color3="colorSettings.fontColor"
          :targetScore="gameChallenges[counter]?.targetScore || 10"
          :buttonText="getButtonText(gameChallenges[counter])"
          @update-screens-event="updateScreensHandler"
          v-if="showMainScreen && gameChallenges"
          class="main-screen"
        />
        <div class="gameplay-container" v-if="showStartGameScreen">
          <img
            :src="gameChallenges[counter]?.gameDetails.games.battle_room_banner"
            class="game-banner"
          />
          <ArrowSvg
            :counter="counter"
            :total-length="arrayLength"
            v-if="showMainScreen && gameChallenges"
            class="arrow"
            @increase-decrease-games-counter="buttonCounter"
          />
          <div class="game-name">
            {{ gameChallenges[counter]?.gameDetails?.games?.name }}
          </div>
          <div class="coupon-title-container">
            <div
              class="coupon-title"
              v-bind:style="{
                color: `${colorSettings.primary}`,
              }"
              v-if="showStartGameScreen"
            >
              <p>
                Stand a chance to win<br />
                {{ gameChallenges[counter]?.winningAmount }} Points
              </p>
            </div>
          </div>
        </div>
        <div class="result-screen" v-if="resultScreen">
          <img
            :src="gameChallenges[counter]?.gameDetails.games.battle_room_banner"
            class="game-over-image"
          />
          <div class="text-container">
            <div class="score">
              Your Score: <span>{{ playerScore }}</span>
            </div>
            <div
              class="game-result-text"
              v-if="isWinScreen || retryScreen || noRetryScreen || alreadyWon"
            >
              {{ gameState }}
            </div>
            <div class="coupon-text" v-if="isWinScreen">
              {{ gameChallenges[counter]?.message?.winMessage }}
              <span>{{ gameChallenges[counter]?.winningAmount }} Points</span>
            </div>
            <div class="coupon-text" v-if="alreadyWon">
              You've already won
              <span>{{ gameChallenges[counter]?.winningAmount }} Points</span>
            </div>
            <div class="coupon-text" v-if="retryScreen">
              Retry you have
              {{ attemptsLeft }} Attempts left
            </div>
            <div class="coupon-text" v-if="noRetryScreen">
              0 Attempt Left. You can play again tomorrow
            </div>
            <!-- <div class="coupon" v-if="isWinScreen">
              <CouponCodeSvg
                :color1="colorSettings.primary"
                :couponCode="code"
                @copy-to-clipboard="copyCouponCode"
              />
            </div> -->
            <div class="empty-div" v-if="retryScreen || noRetryScreen"></div>
          </div>
        </div>
        <GameScreen
          :gameHostUrl="gameChallenges[counter]?.gameDetails?.games.host_url"
          :challenge="gameChallenges[counter]?.id"
          :targetProp="gameChallenges[counter]?.targetScore || 10"
          :isLandscape="
            landscape(
              gameChallenges[counter]?.gameDetails?.games?.options?.orientation
            )
          "
          @is-Landscape="updateIsLandscape"
          @on-result-screen-load="resultScreenLoad"
          v-if="showGameScreen"
          class="game-screen"
        />
        <div id="error"></div>
        <div class="game-component"></div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import get from "lodash/get";
import MainScreenSvg from "./components/MainScreenSvg.vue";
import GameScreen from "./GameScreen.vue";
// import CouponCodeSvg from "./components/CouponCodeSvg.vue";
import ArrowSvg from "./components/ArrowSvg.vue";
import HomeSvg from "./components/HomeSvg.vue";
import { Constants } from "./constants.js";
import { FrolicEvents } from "./event.js";
import { gameUrl, proxyUrl } from "./url.js";
import { claimGame } from "./service";
export default {
  name: "FrolicComponent",
  components: {
    MainScreenSvg,
    GameScreen,
    // CouponCodeSvg,
    ArrowSvg,
    HomeSvg,
  },
  data() {
    return {
      host: window.location.host || Constants.FROLIC_Z0_BASE_URL,
      BASE_URL: "",
      applicationId: "",
      userId: "",
      userEmail: "",
      userPhone: "",
      companyId: "",
      params: "",
      slotOfferParam: "",
      spinCount: 0,
      WALLET_URL: "",
      BANNER_URL: "",
      audioEnabled: true,
      isGuest: false,
      requestOptionsGET: {},
      requestOptionsPOST: {},
      couponsTitle: {},
      gameChallenges: [],
      colorSettings: {
        primary: "#26533B",
        secondary: "#FFE710",
        fontColor: "#26533B",
      },
      playerScore: "0",
      gameState: "",
      isWinScreen: false,
      alreadyWon: false,
      showStartGameScreen: false,
      showMainScreen: true,
      showGameScreen: false,
      resultScreen: false,
      buttonText: "Start Game",
      retryScreen: false,
      attemptsLeft: Number.MAX_SAFE_INTEGER,
      loader: false,
      outOfAttemptsScreen: false,
      coupons: [],
      noRetryScreen: false,
      gameScreeHeight: "130%",
      backgroundUrl: Constants.BG_URL,
      backgroundColor: "#0a0a14",
      arrayLength: 0,
      counter: 0,
      code: "",
      isLandscape: false,
      challengeParam: null,
      orient: "landscape",
    };
  },
  async mounted() {
    this.showMainScreen = false;
    this.showStartGameScreen = false;
    this.loader = true;

    await Promise.allSettled([
      (this.challengeParam = this.getParams(Constants.PARAMS)),
      this.getData(),
      // this.getThemeSettings(),
    ]).catch((error) => {
      console.error("Something went wrong!", error);
    });

    window.addEventListener("message", (event) => {
      if (event && event?.data?.type === Constants.CHALLENGE_EVENT_TYPE) {
        this.gameChallenges = this.gameChallenges.filter(
          (gameChallenge) => event.data?.message === gameChallenge._id
        );
        const eventObj = {
          type: "FrolicOpenWidget",
        };
        window.postMessage(eventObj, "*");
        this.arrayLength = 0;
      } else if (
        event &&
        event?.data?.type === Constants.FETCH_DATA_EVENT_TYPE
      ) {
        this.getData();
      }
    });
    this.loader = false;
    this.showStartGameScreen = true;
    this.showMainScreen = true;
  },
  methods: {
    getQueryParam(key) {
      const queryString = window.location.search;
      const params = new URLSearchParams(queryString);
      return params.get(key);
    },
    homeButtonClick() {
      this.showStartGameScreen = true;
      this.showMainScreen = true;
      this.noRetryScreen = false;
      this.showGameScreen = false;
      this.isWinScreen = false;
      this.retryScreen = false;
      this.resultScreen = false;
      this.buttonText = Constants.START_GAME;
      this.alreadyWon = false;
    },
    getButtonText(challenge) {
      if (this.showMainScreen && this.showStartGameScreen) {
        // this.isGuest = window.__frolic_engage.conf.user.isGuest;
        if (challenge && challenge.isGuestEnabled) {
          return (this.buttonText = Constants.START_GAME);
        } else {
          if (this.isGuest) {
            return (this.buttonText = Constants.LOGIN_TO_PLAY);
          } else {
            return (this.buttonText = challenge?.buttonText);
          }
        }
      } else {
        return this.buttonText;
      }
    },
    onShopNowClick() {
      const currentUrl = window.location.href;
      const url = new URL(currentUrl);
      const paramToRemove = "f";
      const challengeParam = "c";
      url.searchParams.delete(paramToRemove);
      if (currentUrl.includes(challengeParam + "=")) {
        url.searchParams.delete(challengeParam);
      }
      const updatedUrl = url.href;
      history.replaceState(null, null, updatedUrl);
    },
    hideWidget() {
      const event = { type: Constants.HIDE_WIDGET_EVENT };
      window.postMessage(event, "*");
    },
    updateScreensHandler() {
      if (this.buttonText === Constants.SHOP_NOW) {
        this.onShopNowClick();
        this.showMainScreen = false;
        this.noRetryScreen = false;
        this.showStartGameScreen = false;
        this.showGameScreen = false;
        this.isWinScreen = false;
        this.retryScreen = false;
        this.resultScreen = false;
        FrolicEvents.hideWidget();
      } else if (this.buttonText === Constants.OUT_OF_ATTEMPTS) {
        this.showMainScreen = true;
        this.noRetryScreen = true;
        this.showStartGameScreen = false;
        this.showGameScreen = false;
        this.isWinScreen = false;
        this.retryScreen = false;
        this.resultScreen = true;
      } else if (this.buttonText === Constants.LOGIN_TO_PLAY) {
        window.__frolic_engage.conf.widgets.forEach((widget) => {
          console.log(widget);
          var dest_url = window.location.href;
          var baseUrl =
            window.location.protocol + "//" + window.location.host + "/";
          var redirect_url = dest_url.replace(baseUrl, "");
          window.location = `${baseUrl}auth/login/?redirectUrl=/${redirect_url}`;
        });
        return;
      } else {
        this.showMainScreen = false;
        this.showStartGameScreen = false;
        this.showGameScreen = true;
        this.isWinScreen = false;
        this.retryScreen = false;
        this.resultScreen = false;
        this.noRetryScreen = false;
      }
    },
    // copyCouponCode() {
    //     try {
    //         if (
    //             this.code !== Constants.COPY_TEXT &&
    //             this.code !== Constants.ALREADY_WON
    //         ) {
    //             navigator.clipboard.writeText(this.code);
    //         }
    //         if (window?.__fyndKit) {
    //             console.log("Native Android 1");
    //             if (this.code !== Constants.COPY_TEXT) {
    //                 window?.__fyndKit?.copyToClipboard(this.code);
    //             }
    //         }
    //         if (__fyndKit) {
    //             console.log("Native Android 2");
    //             if (this.code !== Constants.COPY_TEXT) {
    //                 __fyndKit?.copyToClipboard(this.code);
    //             }
    //         }
    //     } catch (e) {
    //         console.error(error);
    //     } finally {
    //         const previousCode = this.code;
    //         this.code = Constants.COPY_TEXT;
    //         setTimeout(() => {
    //             this.code = previousCode;
    //         }, 2000);
    //     }
    // },
    buttonCounter(direction) {
      if (direction === Constants.LEFT) {
        if (this.counter <= 0) {
          this.counter = 0;
        } else {
          this.counter--;
        }
      } else if (direction === Constants.RIGHT) {
        if (this.counter >= this.arrayLength) {
          this.counter = this.arrayLength;
        } else {
          this.counter++;
        }
      }
    },
    async resultScreenLoad(data) {
      if (data.score >= this.gameChallenges[this.counter]?.targetScore) {
        const result = await claimGame(this.gameChallenges[this.counter].id);
        if (result) {
          this.isLandscape = false;
          this.emitIsLandscape();
          FrolicEvents.setCrossPositionInPortrait();
          if (result.payload?.winningStatus === Constants.ALREADY_WON) {
            this.alreadyWon = true;
            this.winScreen = true;
            this.gameState = Constants.ALREADY_WON;
            this.buttonText = Constants.RETRY;
          } else {
            this.isWinScreen = true;
            this.gameState =
              this.gameChallenges[this.counter]?.message?.winTitle;
            this.buttonText = Constants.SHOP_NOW;
          }
          this.code = result.couponCode;
          this.attemptsLeft = data.attemptsLeft;
          this.showGameScreen = false;
          this.showStartGameScreen = false;
          this.resultScreen = true;
          this.showMainScreen = true;
          this.playerScore = data.score;
        }
      } else if (
        data.score < this.gameChallenges[this.counter]?.targetScore &&
        data.attemptsLeft > 0
      ) {
        this.isLandscape = false;
        this.emitIsLandscape();
        FrolicEvents.setCrossPositionInPortrait();
        this.attemptsLeft = data.attemptsLeft;
        this.winScreen = false;
        this.showGameScreen = false;
        this.showStartGameScreen = false;
        this.resultScreen = true;
        this.showMainScreen = true;
        this.retryScreen = true;
        (this.gameState =
          this.gameChallenges[this.counter]?.message?.tryAgainMessage),
          (this.playerScore = data.score);
        this.buttonText = Constants.RETRY;
      } else if (data.attemptsLeft == 0 || data.isOutOfAttempts) {
        this.isLandscape = false;
        this.emitIsLandscape();
        FrolicEvents.setCrossPositionInPortrait();
        this.attemptsLeft = data.attemptsLeft;
        this.winScreen = false;
        this.showGameScreen = false;
        this.showStartGameScreen = false;
        this.resultScreen = true;
        this.showMainScreen = true;
        this.noRetryScreen = true;
        this.gameState = Constants.FAILED;
        this.playerScore = data.score;
        this.buttonText = Constants.OUT_OF_ATTEMPTS;
      }
    },
    proxyUrl(url) {
      url = proxyUrl(url);
      return url;
    },
    landscape(orientation) {
      if (orientation === this.orient) {
        this.emitIsLandscape(true);
        FrolicEvents.setCrossPositionInLandscape();
        return true;
      }
      return false;
    },
    updateIsLandscape(value) {
      this.isLandscape = value;
    },
    emitIsLandscape(isLandscape) {
      this.$emit("is-Landscape", isLandscape);
    },
    toggleSoundEnable() {
      this.audioEnabled = !this.audioEnabled;
      if (this.audioEnabled) {
        document.getElementsByClassName(
          "audio-enable-button"
        )[0].style.visibility = "visible";
        document.getElementsByClassName(
          "audio-enable-button"
        )[1].style.visibility = "hidden";
      } else {
        document.getElementsByClassName(
          "audio-enable-button"
        )[1].style.visibility = "visible";
        document.getElementsByClassName(
          "audio-enable-button"
        )[0].style.visibility = "hidden";
      }
    },
    async getTotalCoins() {
      const res = await fetch(this.WALLET_URL, this.requestOptionsGET);
      if (!res.ok) {
        let err = new Error(`HTTP error! status: ${res.status}`);
        err.status = res.status;
        throw err;
      }
      return await res.json();
    },

    async fetchAudio(audioList) {
      const audioFiles = await Promise.all(
        audioList.map(async (e) => {
          const resp = await fetch(e.src);
          return resp;
        })
      );
      return audioFiles;
    },

    localSegments(prizes) {
      let local = prizes.map((t, index) => {
        let obj = {
          text: t.name,
          // fillStyle: `${index % 2 === 0 ? "#F5D352" : "#F11A00"}`,
          fillStyle: `${
            index % 2 === 0
              ? this.colorSettings.primary
              : this.colorSettings.secondary
          }`,
          textStrokeStyle: `${index % 2 === 0 ? "#FFFFFF" : "#FFFFFF"}`,
          textFillStyle: `${index % 2 === 0 ? "#FFFFFF" : "#FFFFFF"}`,
          textFontSize: t.name.length > 10 ? 18 : 24,
        };
        return obj;
      });
      return local;
    },

    imageTransform(uri, transformer) {
      if (!transformer) return uri;
      const url = new URL(uri);
      if (["cdn.pixelbin.io"].includes(url.host)) {
        return uri.replace(`/original/`, `/${transformer}/`);
      }
      return uri;
    },

    async getCoupons(couponId) {
      const res = await fetch(
        this.BASE_URL +
          `/company/${this.companyId}/application/${this.applicationId}/coupons/${couponId}`,
        this.requestOptionsGET
      );
      return await res.json();
    },
    getParams(key) {
      const queryString = window.location.search;
      const params = new URLSearchParams(queryString);
      return params.get(key);
    },
    async getData() {
      try {
        this.host = window.location.host || Constants.FROLIC_Z0_BASE_URL;
        const frolicObject = window.__frolic_engage;
        this.applicationId = get(frolicObject, "conf.application_id");
        this.userId = get(frolicObject, "conf.user.userId");
        this.userEmail = get(frolicObject, "conf.user.emails[0].email")
          ? get(frolicObject, "conf.user.emails[0].email")
          : "";
        this.userPhone = get(frolicObject, "conf.user.phoneNumbers[0].phone");
        this.companyId = get(frolicObject, "conf.company_id");
        let url = gameUrl;
        if (this.challengeParam !== null) {
          url += `?c=${this.challengeParam}`;
        }
        const data = await axios.get(url);
        this.gameChallenges = data?.data?.payload?.campaigns;
        this.arrayLength = this.gameChallenges.length - 1;
        this.colorSettings.primary =
          this.gameChallenges[this.counter]?.color.primary;
        this.colorSettings.secondary =
          this.gameChallenges[this.counter]?.color.secondary;
        this.colorSettings.fontColor =
          this.gameChallenges[this.counter]?.color.btnText;
      } catch (error) {
        console.log(error);
      }
    },

    // async getThemeSettings() {
    //   try {
    //     console.log(this.gameChallenges[0]);
    //   } catch (error) {
    //     throw new Error(error.message);
    //   }
    // },
    formattedTargetProp(targetScore) {
      const prop = targetScore || "";
      const style =
        prop.length === 1 ? "" : "text-align: center; position: relative;";
      return { value: prop, style };
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Quantico:wght@400;500;600;700&display=swap");

#app {
  font-family: "Quantico";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
}

:root {
  --main-bg-color: #282828;
  --outer-circle-color: #282828;
  --circle-border-color: #f2b04c;
  --win-strips: linear-gradient(
    180deg,
    rgba(196, 196, 196, 0) 0%,
    rgba(255, 193, 0, 0.7) 100%
  );
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Quantico";
  margin: 0;
  padding: 0;
  background: transparent;
  color: #fff;
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a {
  text-decoration: none;
}
.arrow {
  width: 523px;
  height: 54px;
  display: flex;
  margin-top: 11rem;
  position: absolute;
  align-items: center;
  align-content: center;
  justify-content: space-evenly;
  flex-direction: column;
  flex-wrap: wrap;
}
.text-container {
  width: 100%;
  height: 50%;
  margin-bottom: 6%;
}
.text-container span {
  color: v-bind("colorSettings.secondary");
}
.coupon-text {
  font-family: Quantico;
  font-size: 144%;
  font-weight: 400;
  margin-left: 7%;
  -webkit-margin-end: auto;
  margin-inline-end: auto;
  line-height: 100%;
  letter-spacing: 0;
  width: 290px;
  text-align: center;
  padding: 6px;
  color: v-bind("colorSettings.primary");
}
.coupon-text span {
  color: v-bind("colorSettings.primary");
}
.result-screen {
  position: absolute;
  top: 52%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 340px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.game-over-image {
  width: 36%;
}
.resize-mainscreen {
  width: 100%;
  height: 99%;
  position: relative;
}

.game-result-text {
  font-family: Quantico;
  font-size: 2rem;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: 0;
  text-align: center;
  color: #fff;
}
.game-banner {
  width: 64%;
  margin-top: 3rem;
  user-select: none;
}
.game-name {
  font-size: 28px;
  font-weight: 700;
  color: #fff;
  width: 100%;
}
.coupon-title-container {
  height: 18%;
  width: 100%;
  display: flex;
  position: relative;
  top: 37px;
  justify-content: center;
  margin-top: 0%;
}
.coupon-title {
  font-size: 18px;
  line-height: 25px;
  width: 80%;
}
.coupon {
  margin-top: 1rem;
  transform: scale(0.77);
}
.score {
  font-size: 21px;
  font-weight: 400;
  color: #fff;
  line-height: 36px;
  letter-spacing: 0;
}
.score span {
  color: v-bind("colorSettings.primary");
}
.main-screen {
  width: 100%;
  margin-top: 13%;
  height: 91%;
}
.coupon-code {
  /* font-family: "PP Object Sans"; */
  font-style: normal;
  font-weight: 500;
}

#coupon-code-copied {
  /* display: none; */
  visibility: hidden;
  /* font-family: "PP Object Sans"; */
  /* font-style: normal;
      margin-top: 1rem;
      margin-bottom: 0.5rem; */

  font-style: normal;
  font-size: 0.8rem;
  margin-top: 0.5rem;
  color: black;
  margin-bottom: 0.5rem;
}

.coupon-code-copy-btn {
  height: 1.5rem;
  cursor: pointer;
}

#coupon-section-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: #ebffe4;
  gap: 1rem;
  border-radius: 10px;
  /* width: 60%; */
  padding: 0.5rem 0.8rem 0.5rem 0.7rem;
  margin-top: 1rem;
  color: #00a519;
}

button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.frolicgames-container {
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
  border-top-left-radius: 15px;
  position: relative;
  border-top-right-radius: 15px;
  top: 70px;
}
.frolicgames-container-landscape {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 50%;
  height: 53vh;
  margin-top: 6%;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  overflow: hidden;
  /* background-image: url("https://cdn.pixelbin.io/v2/kraftnft/original/kraftnft/assets/SpinWheelBackgroundImage.png"); */
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
}
.gameplay-container {
  position: absolute;
  top: 48%;
  transform: translate(-50%, -50%);
  height: 400px;
  width: 340px;
  top: 50%;
  left: 50%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
}
.head {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.home-button-container {
  position: relative;
}
.home-btn {
  display: flex;
  position: absolute;
  bottom: 17rem;
  width: 76px;
  transform: scale(0.5);
}
#close-spinner {
  padding-right: 5px;
}

.body {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  height: 100%;
}

#loader {
  background-image: url(https://cdn.pixelbin.io/v2/dawn-rice-752715/original/project1.gif);
  background-size: contain;
  background-repeat: no-repeat;
  width: 378px;
  height: 641px;
  background-position-y: center;
}
#error {
  display: none;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 1.1em;
}

.below-spinner-element {
  position: absolute;
  /* transform: scale(0.6); */
  height: 5rem;
  width: 100%;
  /* top: 6rem; */

  margin-top: 33%;
  display: flex;
}

.below-spinner-element-container {
  /* position: absolute;
    transform: scale(0.6); */
  width: 100%;
  /* top: 6rem; */
}

/* Terms page */
#terms {
  display: none; /* flex */
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 90vh;
  padding: 0 8px;
}
.terms-text {
  width: 100%;
  height: 100%;
}
.terms-header {
  align-self: flex-start;
}
.terms-header h2 {
  margin: 1.3em 0 0.8em 0;
  font-size: 14px;
  line-height: 24px;
}

.terms-content {
  align-self: flex-start;
  list-style-type: none;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: #88888f;
  /* min-height: 300px; */
  height: 65vh;
  overflow-y: scroll;
  overflow-x: hidden;
}
.terms-content li {
  margin-top: 12px;
}

.terms-footer {
  margin: 24px 0;
}

.terms-b-btn {
  font-size: 14px;
  background: #ffffff26;
  padding: 14px 48.5px;
  border-radius: 100px;
  margin-bottom: 24px;
}

/* Main Page */
#main {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 95vh;
  width: 100%;
  /* height: 87vh; */
}
.wheel {
  width: 100%;
  margin-bottom: 45%;
  position: relative;
}

.daily-txt {
  /* font-size: 14px;
      font-weight: 600; */

  /* font-family: "Schnyder M App"; */
  font-style: normal;
  /* font-weight: 300; */
  font-size: 23px;
  /* line-height: 86px; */
  text-align: center;
  color: #ffffff;
}

.stand-a-change-text {
  /* font-family: "PP Object Sans"; */
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  /* line-height: 52px; */
  text-align: center;
  color: rgba(255, 255, 255, 0.6);
  margin-bottom: 0.5rem;
}

.element-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: red;
}

#terms-anchor {
  display: none;
  font-size: 12px;
  font-weight: 500;
  text-decoration: underline;
}

.grayscaled {
  filter: grayscale(75%) !important;
  /* color: gray !important; */
}
.gift-box {
  z-index: 2;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
.light-shades-3 {
  position: absolute;
  bottom: -3vh;
  left: 50%;
  transform: translateX(-50%);
}

.empty-div {
  height: 60px;
}
.winnings-card {
  z-index: 10;
  position: absolute;
  top: 20vh;
  left: 50%;
  transform: translateX(-50%);
  /* width: 90%; */
  height: 65vh;
  /* background: #2a2a41; */
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.reward {
  height: 18vh;
  padding: 11em 1em 12em 1em;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  border: 3px dashed #00a519;
  border-radius: 20px;
  margin: 1rem;
}

#empty-result-icon {
  transform: scale(0.5);
  margin-bottom: -1.5rem;
}

#won-result-icon {
  transform: scale(0.5);
  margin-bottom: -2rem;
}

.game-screen {
  position: relative;
}

.reward-wrapper {
  /* border-radius: 95.4953px 95.4953px 95.5px 95.5px; */
  border-radius: 20px;
  background-color: white;
  /* background: linear-gradient(
            180deg,
            #f11a00 -79.26%,
            #b41f27 118.06%,
            #682b23 118.08%
        ),
        linear-gradient(
            90deg,
            #ffe78b 0.14%,
            rgba(255, 231, 139, 0) 22.13%,
            rgba(255, 231, 139, 0.44) 46.22%,
            rgba(255, 231, 139, 0) 81.84%,
            #ffe78b 100.69%
          ); */
}

#reward-title {
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  -moz-background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-color: #00a519;
  font-size: 20px;
  background-size: 100%;
  background-repeat: repeat;
  font-style: normal;
  font-weight: 500;
  text-align: center;
  color: #ffffff !important;
}

#reward-text {
  /* font-size: 18px; */
  /* font-weight: 500; */
  /* text-align: center; */
  /* background-color: #ee9b00; */
  /* background: linear-gradient(180deg, #f7dd75 0%, #ee9b00 100%); */
  /* background-size: 100%; */
  /* background-repeat: repeat; */
  /* -webkit-background-clip: text; */
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;

  font-size: 14px;
  font-weight: 500;
  text-align: center;
  /* background: linear-gradient(180deg, #f7dd75 0%, #ee9b00 100%); */
  background-size: 100%;
  background-repeat: repeat;
  -webkit-background-clip: text;
  background-color: #676767;
  -webkit-text-fill-color: transparent;
  /* font-family: "PP Object Sans"; */
  font-style: normal;
  font-weight: 400;
  /* font-size: 48px; */
  /* line-height: 62px; */
  display: flex;
  align-items: center;
  text-align: center;
  margin-top: 0.5rem;
  color: #ffffff;
}

.total-coins {
  width: 100%;
  height: 14vh;
  /* display: flex; */
  display: none;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  font-size: 18px;
  font-weight: 400;
  padding: 1em;
}
#wallet-box {
  background: #fefefe1a;
  font-weight: 600;
  min-width: 35%;
  margin: auto;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 5px 10px;
  border-radius: 5px;
}

.play {
  width: 100%;
  /* height: 14vh; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-bottom: 0.5em; */
  margin-top: 14%;
}

.play img {
  width: 100%;
}

#promotion-box {
  width: calc(100% - 2rem);
  border-radius: 6px;
  white-space: nowrap;
  overflow-x: auto;
  margin: 10px 0;
}
#promotion-box img {
  width: 100%;
  height: 100%;
}

/* .audio-button-container {
    position: relative;
    width: 100%;
  }

  .audio-enable-button {
    position: absolute;
    top: 1.6rem;
    left: 0;
    height: 2.2rem;
    cursor: pointer;
  } */

#play-btn:hover {
  filter: brightness(80%);
  transition-delay: 0.2s;
}

#spin-btn {
  /* color: #ea3b5c; */
  color: black;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  height: 2.5rem;
  margin-top: 4%;
  cursor: pointer;
}
.StandText {
  bottom: -10px;
}

/* Media queries */
@media only screen and (max-height: 560px) {
  .terms-content {
    height: 62vh;
  }
  .terms-footer {
    margin: 10px 0;
  }
  #terms,
  #winnings,
  #main {
    height: 85vh;
  }
}
@media only screen and (max-height: 520px) {
  .play {
    margin-bottom: 0;
  }
  .play img {
    width: 90%;
  }
}
@media only screen and (max-height: 480px) {
  .play img {
    width: 85%;
  }
  .total-coins {
    font-size: 16px;
  }
  #wallet-box {
    padding: 3px 10px;
    border-radius: 4px;
  }
  #promotion-box {
    height: 22vh;
    width: calc(100% - 3rem);
  }
  #play-btn {
    font-size: 14px;
    height: 40px;
    width: 130px;
  }
  .terms-content {
    height: 60vh;
  }
  .terms-b-btn {
    font-size: 12px;
    padding: 12px 42.85px;
  }
}
@media only screen and (max-height: 430px) {
  .terms-content {
    height: 55vh;
  }
  #terms,
  #winnings,
  #main {
    height: 80vh;
  }
  .winnings-card {
    top: 15vh;
    height: 70vh;
  }
  #promotion-box {
    height: 18vh;
    width: calc(100% - 3rem);
  }
  #spin-btn {
    font-size: 14px;
    height: 40px;
    width: 130px;
    cursor: pointer;
  }
}
@media only screen and (max-height: 400px) {
  .play img {
    width: 78%;
  }
  .total-coins {
    font-size: 14px;
  }
  .terms-b-btn {
    font-size: 12px;
    padding: 10px 35.5px;
  }
  .terms-content {
    height: 50vh;
  }
}
@media only screen and (min-width: 700px) {
  /* .container {
          padding: 8px 32px;
      } */
  .winnings-card {
    width: 70%;
  }
  .total-coins {
    font-size: 24px;
  }
  #reward-text {
    font-size: 24px;
  }
  #reward-title {
    font-size: 32px;
  }
  #wallet-box {
    justify-content: space-around;
    padding: 8px 16px;
  }
  #terms-anchor {
    font-size: 18px;
  }
  .daily-txt {
    font-size: 22px;
  }
  .terms-content {
    font-size: 18px;
  }
  .head {
    font-size: 24px;
  }
  #promotion-box {
    margin: 1rem;
  }
}
@media only screen and (min-width: 1024px) {
  /* .container {
          padding: 12px 40px;
      } */
  .winnings-card {
    width: 70%;
  }
  .total-coins {
    font-size: 34px;
  }
  #reward-text {
    font-size: 36px;
  }
  #reward-title {
    font-size: 48px;
  }
  #wallet-box {
    justify-content: space-around;
    padding: 12px 20px;
  }
  #wallet-box img {
    width: 40px;
  }
  #terms-anchor {
    font-size: 22px;
  }
  .daily-txt {
    font-size: 24px;
  }
  .terms-content {
    font-size: 22px;
  }
  .head {
    font-size: 28px;
  }
  #promotion-box {
    margin: 1rem;
  }
}

/* frolic Games media query */
/* Mobile screens (320px to 480px) */
@media screen and (min-width: 320px) and (max-width: 480px) {
  .gameplay-container {
    top: 40%;
    height: 340px;
    width: 280px;
  }
  .main-screen {
    width: 100%;
    transform: translate(-50%, -50%);
    height: 85%;
    top: 40%;
    left: 50%;
    position: absolute;
  }
  .frolicgames-container {
    width: 100%;
    height: 100%;
    top: 0%;
    margin-top: 0%;
  }
  .result-screen {
    top: 47%;
  }
  .coupon-title {
    position: relative;
    bottom: -14px;
  }
  .arrow {
    width: 370px;
    margin: 12rem auto;
  }
  .coupon-title-container {
    bottom: -37px;
  }
  .home-btn {
    top: -1rem;
    position: fixed;
  }
  .frolicgames-container-landscape {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200vh;
    height: 57vh;
    transform-origin: center center;
    transform: rotate(90deg);
    margin: 0;
    position: relative;
    top: 9rem;
  }
}

/* Laptop and Tablet screens (481px to 1024px) */
@media screen and (min-width: 481px) and (max-width: 1024px) {
  .frolicgames-container {
    height: 756px;
    width: 397px;
    transform: translate(-50%, -50%);
    top: 38%;
    left: 50%;
    position: absolute;
  }
  .result-screen {
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .arrow {
    width: 395px;
  }
  .game-name {
    position: relative;
    bottom: 20px;
  }
  .coupon-title {
    position: relative;
    top: -33px;
  }
  .home-btn {
    bottom: 16rem;
    left: 0;
  }
  .frolicgames-container-landscape {
    width: 100%;
    height: 50%;
  }
}

/* Large desktops (1024px to 1919px) */
@media screen and (min-width: 1920px) {
  .frolicgames-container-landscape {
    max-width: 50%;
    height: 53vh;
  }
  .gameplay-container {
    top: 50%;
  }
}
</style>
