export const Constants = {
    BG_URL: `"https://cdn.pixelbin.io/v2/dawn-rice-752715/original/background.svg"`,
    PARAMS: "c",
    HIDE_WIDGET_EVENT: "FrolicHideWidget",
    CHALLENGE_EVENT_TYPE: "OpenChallenge",
    FETCH_DATA_EVENT_TYPE: "fetchData",
    START_GAME: "Start Game",
    LOGIN_TO_PLAY: "Login to Play",
    SHOP_NOW: "Shop Now",
    OUT_OF_ATTEMPTS: "Out of Attempts",
    COPY_TEXT: "Copied",
    ALREADY_WON: "Already Won",
    LEFT: "left",
    RIGHT: "right",
    BRAVO: "Bravo",
    TRY_AGAIN: "Ugh, Play Again",
    FAILED: "We are sorry!!",
    RETRY: "Retry",
    FROLIC_LIVE_BASE_URL: "https://games.frolicz0.de/",
    LANDSCAPE: "landscape",
    GAME_MODE_TYPE: "tournament",
    FROLIC: "frolic",
    LANDSCAPE_COMPONENT: "TitleBarLandscapeSvg",
    PORTRAIT_COMPONENT: "TitleBarSvg",
    FROLIC_Z0_BASE_URL: "api.frolicz0.de",
};
