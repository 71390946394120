 <template>
    <svg
        width="1399"
        height="116"
        viewBox="0 0 1399 116"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle
            v-if="shouldDisplayArrow('left')"
            @click="emitCounterChanged('left')"
            cx="58"
            cy="58"
            r="57"
            fill="black"
            fill-opacity="0.9"
            stroke="#2F2F2F"
            stroke-width="2"
            class="leftArrow"
        />
        <g clip-path="url(#clip0_887_71409)" v-if="shouldDisplayArrow('left')">
            <path
                @click="emitCounterChanged('left')"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M45.6433 60.3562C45.0184 59.7311 44.6674 58.8834 44.6674 57.9995C44.6674 57.1156 45.0184 56.2679 45.6433 55.6429L64.5 36.7862C64.8075 36.4678 65.1753 36.2139 65.582 36.0392C65.9886 35.8645 66.426 35.7725 66.8686 35.7687C67.3112 35.7648 67.7502 35.8492 68.1598 36.0168C68.5695 36.1844 68.9417 36.4319 69.2546 36.7449C69.5676 37.0578 69.8151 37.43 69.9827 37.8397C70.1503 38.2493 70.2347 38.6883 70.2308 39.1309C70.227 39.5735 70.135 40.0109 69.9603 40.4175C69.7856 40.8242 69.5317 41.192 69.2133 41.4995L52.7133 57.9995L69.2133 74.4995C69.8205 75.1282 70.1565 75.9702 70.1489 76.8442C70.1413 77.7182 69.7907 78.5542 69.1727 79.1722C68.5547 79.7903 67.7186 80.1408 66.8447 80.1484C65.9707 80.156 65.1287 79.82 64.5 79.2129L45.6433 60.3562Z"
                fill="white"
                class="leftArrow"
            />
        </g>
        <circle
            v-if="shouldDisplayArrow('right')"
            @click="emitCounterChanged('right')"
            cx="1341"
            cy="58"
            r="57"
            fill="black"
            fill-opacity="0.9"
            stroke="#2F2F2F"
            stroke-width="2"
            class="rightArrow"
        />
        <g clip-path="url(#clip1_887_71409)" v-if="shouldDisplayArrow('right')">
            <path
                @click="emitCounterChanged('left')"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M1353.36 55.643C1353.98 56.2681 1354.33 57.1157 1354.33 57.9996C1354.33 58.8835 1353.98 59.7312 1353.36 60.3563L1334.5 79.213C1334.19 79.5313 1333.82 79.7853 1333.42 79.96C1333.01 80.1347 1332.57 80.2266 1332.13 80.2305C1331.69 80.2343 1331.25 80.15 1330.84 79.9824C1330.43 79.8148 1330.06 79.5672 1329.75 79.2543C1329.43 78.9413 1329.18 78.5691 1329.02 78.1595C1328.85 77.7498 1328.77 77.3109 1328.77 76.8683C1328.77 76.4257 1328.86 75.9883 1329.04 75.5816C1329.21 75.1749 1329.47 74.8071 1329.79 74.4996L1346.29 57.9996L1329.79 41.4996C1329.18 40.871 1328.84 40.0289 1328.85 39.155C1328.86 38.281 1329.21 37.4449 1329.83 36.8269C1330.45 36.2089 1331.28 35.8583 1332.16 35.8507C1333.03 35.8431 1333.87 36.1791 1334.5 36.7863L1353.36 55.643Z"
                fill="white"
                class="rightArrow"
            />
        </g>
        <defs>
            <clipPath id="clip0_887_71409">
                <rect
                    width="80"
                    height="80"
                    fill="white"
                    transform="translate(18 18)"
                />
            </clipPath>
            <clipPath id="clip1_887_71409">
                <rect
                    width="80"
                    height="80"
                    fill="white"
                    transform="translate(1301 18)"
                />
            </clipPath>
        </defs>
    </svg>
</template>

<script>
export default {
    name: "ArrowSvg",
    props: {
        counter: Number,
        totalLength: Number,
    },
    data() {
        return {};
    },
    computed: {
        shouldDisplayArrow() {
            return (direction) => {
                if (direction === "left") {
                    return this.counter > 0;
                } else if (direction === "right") {
                    return this.counter < this.totalLength;
                }
            };
        },
    },
    methods: {
        emitCounterChanged(direction) {
            this.$emit("increase-decrease-games-counter", direction);
        },
    },
};
</script>
<style>
.rightArrow {
    cursor: pointer;
}
.leftArrow {
    cursor: pointer;
}
</style>
